<template>
    <div class="app-planner-total-row-summary">
        <div tabindex="0" :id="'popover-week-total-statistics-' + _uid" class="h-100" v-bind:class="{
                                    'app-planner-blocks-timeline-summary-total-red': statistics.diff < 0, 
                                    'app-planner-blocks-timeline-summary-total-green': statistics.diff >= 0
                                }"> 
                                {{statistics.diff.toString().replace('.', ',')}}
           
        </div>
        <b-popover :target="'popover-week-total-statistics-' + _uid" triggers="focus">
            <template #title><p class="mb-0 app-popover-small-text font-weight-600">{{trans('pl-weekly-stats',173)}} - {{trans('placement-total',213)}}</p></template>
            
            <b-row class="align-items-center">
                <b-col start>
                    <p class="app-popover-small-text mb-0">{{trans('hours-per-week',184)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('sh-worked-hours',215)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('fp-unavailability',172)}}</p>
                </b-col>
                <b-col cols="auto" end>
                    <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{statistics.hours.toString().replace('.', ',')}}</p>
                    <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{statistics.worked.toString().replace('.', ',')}}</p>
                    <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{statistics.notavailable.toString().replace('.', ',')}}</p>
                </b-col>
            </b-row>

            <hr class="mt-1 mb-2" />

            <b-row class="align-items-center">
                <b-col start>
                    <p class="app-popover-small-text mb-0">{{trans('pl-diff',173)}}</p>
                </b-col>
                <b-col cols="auto" end>
                    <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{statistics.diff.toString().replace('.', ',')}}</p>
                </b-col>
            </b-row>
        </b-popover>
    </div>
</template>



<script>
import axios from "@axios";
import moment from "moment";

export default {
    name: 'PlanningPlannerSummaryFooterComponent',

    components: {
    },

    created(){
       
    },

    computed: {       
    
    },

    props:["statistics"],
    
    watch:{
        
    },

    mounted() {
    
    },

    data() {
        return {
           
        };
    },
    
    methods: {

    }
};
</script>

<style scoped>

.app-local-person-function{
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.app-local-person-data{
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.custom-avatar{
    height: 44px;
    width: 44px;
}
</style>
