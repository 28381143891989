<template>
    <div>
        <div class="d-flex app-planner-person-row-info mt-1"> 
            <div>
                <img class="rounded-circle custom-avatar" v-if="person.avatar != null" :src="person.avatar" alt="Avatar" />
                <img class="rounded-circle custom-avatar" v-else-if="person.sex == 2" src="@/assets/images/avatars/female.jpg" alt="Avatar" />
                <img class="rounded-circle custom-avatar" v-else  src="@/assets/images/avatars/male.jpg" alt="Avatar" />
                 
            </div>
            <div class="ml-3">
                
                <div class="app-local-person-data"  v-overflow-tooltip> {{person.person_name}} </div>
                <div class="app-local-person-second-data d-flex align-items-center" v-overflow-tooltip>
                    <span v-bind:class="{ 
                                                'dot-green' : this.person.id_status_const_type != 7, 
                                                'dot-orange' : this.person.id_status_const_type == 7
                                            }"></span>
                    <span v-if="person.phase != null && person.applicant_type == 1">{{trans('phase',3)}} {{person.phase}}</span> 
                    <span v-if="person.applicant_type == 2">{{trans('cp-zzp',190)}}</span> 
                    <span class="app-local-divider" v-if="person.phase != null"> | </span> 
                    <span >{{person.function_name}}</span></div>
            </div>
            <div class="app-local-warning mr-1" v-if="unavailablity">
                <b-icon icon="exclamation-circle-fill" :id="'popover-id-errors'+person.id" tabindex="0" class="app-local-warning-icon"></b-icon>
                <b-popover :target="'popover-id-errors'+person.id" triggers="hover" no-fade custom-class="app-local-popover">
                    <span>{{trans('planner-flexworker-not-available', 211)}}</span>
                </b-popover>
            </div>
            
        </div>
        <div v-if="selected" @click.prevent class="app-local-profile-button" >
            <app-button type="secondary-grey" @click="getProfile"> {{trans('to-profile',175)}} </app-button>
        </div>  
    </div>
</template>

<script>
import axios from "@axios";
import moment from "moment";

export default {
    name: 'PlanningPlannerPersonComponent',

    components: {
    },

    created(){
        this.checkUnavailablity(); 
    },

    props:["person", "selected", "planning"],
    
    watch:{
        "planning": {
            handler: function(val) {
                this.unavailablity = false;
                this.checkUnavailablity();           
            },
            deep:true
        }
    },

    data() {
        return {
            unavailablity: false,
        };
    },
    
    methods: {
        getProfile: function() {
            this.$router.push({ name: "people-person-profile", params: { id_person_data_main: this.person.id } });
        },

        checkUnavailablity(){
            for(let i = 0; i< 4;i++){
                if(this.planning.unavailable[i].length > 0 || this.planning.shifts[i].length > 0){
                    this.planning.unavailable[i].forEach(unavability => {
                        this.planning.shifts[i].forEach(shifts => {
                            if(moment(shifts.date_from,"YYYY-MM-DD hh:mm").isBetween(moment(unavability.date_from,"DD-MM-YYYY hh:mm"),moment(unavability.date_to,"DD-MM-YYYY hh:mm")) || 
                                moment(shifts.date_to,"YYYY-MM-DD hh:mm").isBetween(moment(unavability.date_from,"DD-MM-YYYY hh:mm"),moment(unavability.date_to,"DD-MM-YYYY hh:mm"))){
                                    return this.unavailablity = true;
                            }
                        });
                    });
                }
            }
        }
    }
};
</script>

<style scoped>

.app-local-person-second-data{
    font-size: 10px;
    max-width: 132px;
}

.app-local-divider {
    color:#aaaaaa;    
    margin-left:4px;
    margin-right:4px;
}

.app-local-person-data{
    margin-top:2px;
    font-size: 12px;
    font-weight: 500;
    max-width: 132px;
}

.app-local-profile-button {
    margin-top:16px;
    text-align: center;
}

.custom-avatar{
    margin-top:4px;
    height: 30px;
    width: 30px;
}

.app-local-warning-icon{
    color: red;
    width: 14px;
    margin-top: -5px;
}

.app-local-popover{
    color: red;
}
.app-local-warning{
    margin-left: auto;
}
</style>
