<template>
    <div class="app-planner-total-row">
        <div class="app-planner-total-row-person"> </div>
        <div class="app-planner-total-row-week"></div>
        <planning-planner-summary-footer-week-component :statistics="statistics.weeks[0]" />
        <div class="app-planner-total-row-week"></div>
        <planning-planner-summary-footer-week-component :statistics="statistics.weeks[1]" />
        <div class="app-planner-total-row-week"></div>
        <planning-planner-summary-footer-week-component :statistics="statistics.weeks[2]" />
        <div class="app-planner-total-row-week"></div>
        <planning-planner-summary-footer-week-component :statistics="statistics.weeks[3]" />
        <div class="app-planner-total-row-summary-all" :id="'popover-week-total-statistics-all-' + _uid" tabindex="0"> 
            <div class="h-100" v-bind:class="{
                                    'app-planner-blocks-timeline-summary-total-red': statistics.all.diff < 0, 
                                    'app-planner-blocks-timeline-summary-total-green': statistics.all.diff >= 0
                                }"> 
                                {{statistics.all.diff.toString().replace('.', ',')}} 
            </div>
        </div>

        <b-popover :target="'popover-week-total-statistics-all-' + _uid" triggers="focus" custom-class="app-local-popover-width">
            <template #title><p class="mb-0 app-popover-small-text font-weight-600">4 {{trans('pl-weekly-stats',173)}} - {{trans('placement-total',213)}} </p></template>
            

            <b-row class="align-items-center">
                <b-col start class="mr-0 ml-2 p-0">
                    <p class="app-popover-small-text  mb-0">{{trans('week',3)}}</p>
                </b-col>
                <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                    <p class="app-popover-small-text  mb-0 text-right">{{planner_properties.weeks[0].week_no}}</p>
                </b-col>
                <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                    <p class="app-popover-small-text  mb-0 text-right">{{planner_properties.weeks[1].week_no}}</p>
                </b-col>
                <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                    <p class="app-popover-small-text  mb-0 text-right">{{planner_properties.weeks[2].week_no}}</p>
                </b-col>
                <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                    <p class="app-popover-small-text  mb-0 text-right">{{planner_properties.weeks[3].week_no}}</p>
                </b-col>
                <b-col cols="auto" end style="max-width:30px; min-width:30px;" class="mr-3 ml-0 p-0">
                    <p class="app-popover-small-text  mb-0 text-right">T</p>
                </b-col>
            </b-row>

            <hr class="mt-1 mb-2" />
            <b-row class="align-items-center">
                <b-col start class="mr-0 ml-2 p-0" style="max-width:160px">
                    <p class="app-popover-small-text mb-0">{{trans('hours-per-week',184)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('sh-worked-hours',215)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('fp-unavailability',172)}}</p>
                </b-col>
                <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                    <p class="app-popover-small-text mb-0 text-right">{{statistics.weeks[0].hours.toString().replace('.', ',')}}</p>
                    <p class="app-popover-small-text mb-0 text-right">{{statistics.weeks[0].worked.toString().replace('.', ',')}}</p>
                    <p class="app-popover-small-text mb-0 text-right">{{statistics.weeks[0].notavailable.toString().replace('.', ',')}}</p>
                </b-col>
                <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                    <p class="app-popover-small-text mb-0 text-right">{{statistics.weeks[1].hours.toString().replace('.', ',')}}</p>
                    <p class="app-popover-small-text mb-0 text-right">{{statistics.weeks[1].worked.toString().replace('.', ',')}}</p>
                    <p class="app-popover-small-text mb-0 text-right">{{statistics.weeks[1].notavailable.toString().replace('.', ',')}}</p>
                </b-col>
                <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                    <p class="app-popover-small-text mb-0 text-right">{{statistics.weeks[2].hours.toString().replace('.', ',')}}</p>
                    <p class="app-popover-small-text mb-0 text-right">{{statistics.weeks[2].worked.toString().replace('.', ',')}}</p>
                    <p class="app-popover-small-text mb-0 text-right">{{statistics.weeks[2].notavailable.toString().replace('.', ',')}}</p>
                </b-col>
                <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                    <p class="app-popover-small-text mb-0 text-right">{{statistics.weeks[3].hours.toString().replace('.', ',')}}</p>
                    <p class="app-popover-small-text mb-0 text-right">{{statistics.weeks[3].worked.toString().replace('.', ',')}}</p>
                    <p class="app-popover-small-text mb-0 text-right">{{statistics.weeks[3].notavailable.toString().replace('.', ',')}}</p>
                </b-col>
                <b-col cols="auto" end style="max-width:30px; min-width:30px;" class="mr-3 ml-0 p-0">
                    <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{statistics.all.hours.toString().replace('.', ',')}}</p>
                    <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{statistics.all.worked.toString().replace('.', ',')}}</p>
                    <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{statistics.all.notavailable.toString().replace('.', ',')}}</p>
                </b-col>
            </b-row>

            <hr class="mt-1 mb-2" />

            <b-row class="align-items-center">
                <b-col start class="mr-0 ml-2 p-0">
                    <p class="app-popover-small-text mb-0">{{trans('pl-diff',173)}}</p>
                </b-col>
                <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                    <p class="app-popover-small-text mb-0 text-right">{{statistics.weeks[0].diff.toString().replace('.', ',')}}</p>
                </b-col>
                <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                    <p class="app-popover-small-text mb-0 text-right">{{statistics.weeks[1].diff.toString().replace('.', ',')}}</p>
                </b-col>
                <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                    <p class="app-popover-small-text mb-0 text-right">{{statistics.weeks[2].diff.toString().replace('.', ',')}}</p>
                </b-col>
                <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                    <p class="app-popover-small-text mb-0 text-right">{{statistics.weeks[3].diff.toString().replace('.', ',')}}</p>
                </b-col>
                <b-col cols="auto" end style="max-width:30px; min-width:30px;" class="mr-3 ml-0 p-0">
                    <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{statistics.all.diff.toString().replace('.', ',')}}</p>
                </b-col>
            </b-row>
        </b-popover>               
    </div>
</template>



<script>
import axios from "@axios";
import moment from "moment";
import PlanningPlannerSummaryFooterWeekComponent from './PlanningPlannerSummaryFooterWeekComponent.vue';

export default {
    name: 'PlanningPlannerSummaryFooterComponent',

    components: {
        PlanningPlannerSummaryFooterWeekComponent
    },

    created(){
       
    },

    computed: {       
    
    },

    props:["statistics", "planner_properties"],
    
    watch:{
        
    },

    mounted() {
    
    },

    data() {
        return {
           
        };
    },
    
    methods: {

    }
};
</script>

<style scoped>

.app-local-person-function{
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.app-local-person-data{
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.custom-avatar{
    height: 44px;
    width: 44px;
}
</style>
