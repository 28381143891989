<template>
    <b-card>
        <b-overlay :show="loading" :opacity="0.6">
            <b-row class="app-card-header">
                
                <b-col start>
                    <div class="app-card-tittle app-local-title-height">
                        <div> {{trans('pl-flexworker-planner',173)}} ({{ people.length }})</div>
                    </div>
                </b-col>
                <b-col class="app-switch-div pl-0" >
                    <b-row class="app-switch-row">
                        <label v-overflow-tooltip for="switch_1" class="ml-1">{{trans('pl-show-registrations',209)}}</label>
                        <b-form-checkbox id="switch_1" v-model="filters.show_registered" switch class="app-switch"/>
                    </b-row>
                </b-col>
                <b-col class="app-input-applicant-filter-width pl-0" style="z-index: 1;">
                    <b-form-input type="text" v-model="filters.search" :lazy="true" class="form-control app-input" :class="{'app-input-filled': filters.search.length > 0}" :placeholder="trans('search',3)" size="sm"/>                       
                </b-col>
                 <b-col class="app-input-applicant-filter-width pl-0" >
                    <app-select v-model="filters.office" :type="user_role > 2 ? 'getUserLocations' : 'getLocations'" :refreshIndex.sync="refreshUserLocations" validatorName="Vestiging" validatorRules="required"/>
                </b-col>
                <b-col cols="auto" class="pl-0" end>
                    <app-period-week-selector :listType="filters.type" :onlyWeek="true" :changeArrows="true" :weekDates.sync="filters.week" :periodDates.sync="filters.period" :periodFirstWeek.sync="filters.period_first_week" :key="refresh" :clearable="false"/>
                </b-col>
                 <b-col cols="auto" class="pl-0"  end>
                    <b-button-group  size="sm">
                        <b-btn class="app-input-min-height app-list-button app-local-margin" v-bind:class="{ 'btn-secondary-grey': filters.type !== 1, 'app-list-button-selected':  filters.type === 1 }" @click="filters.type = 1">{{trans('pl-four-weeks-period',210)}}</b-btn>
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.type !== 2, 'app-list-button-selected':  filters.type === 2 }" @click="filters.type = 2">{{trans('week',3)}}</b-btn>
                    </b-button-group>                             
                </b-col>
                <b-col cols="auto" end class="d-flex align-items-center pl-0">
                    <app-refresh :refresh.sync="refresh" :loading.sync="loading_list"/>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col>
                    <div class="app-planner-container">
                        <div class="app-planner-weeks-header">
                            <div class="app-planner-weeks-header-person"/>                       
                                <planning-planner-timeline-header-component destination="planner" :isWeekHeader="true" :minute_pixel_width.sync="minute_pixel_width" :week="week" v-for="week in planner_properties.weeks" :key="week.index" :selected_week.sync="filters.week" :read_only="read_only"/>
                                <div style="min-width:50px;"> </div>
                        </div> 

                        <div class="app-planner-person-row justify-content-center app-table-no-records mt-2" v-if="people != null && people.length == 0 && planner_properties.weeks.length != 0"> {{trans('no-records-to-display',180)}} </div>
                
                        <div class="app-planner-person-row" v-bind:class="{'app-planner-person-row-selected' : item.selected == true}" v-for="(item, index) in people" :key="item.person.id" >    
                            <div @click="selectPerson(item)">
                                <planning-planner-person-component :person="item.person" :selected="item.selected" :planning="people[index].planning"/>
                            </div>
                            <div class="app-planner-person-plan-row-blocks">
                                <planning-planner-blocks-component 
                                                                    :person.sync="people[index]"                                                                
                                                                    :filters.sync="filters"
                                                                    :popover_statistics.sync="popover_week_statistics"
                                                                    :hovered_block.sync="hovered_block"
                                                                    :edited_availability.sync="edited_availability"
                                                                    :week_index="0"
                                                                    :dates="planner_properties.weeks[0].dates"
                                                                    :minute_pixel_width="minute_pixel_width"
                                                                    :read_only="read_only"                                                                 
                                />

                                <planning-planner-blocks-component 
                                                                    :person.sync="people[index]"                                                                
                                                                    :filters.sync="filters"
                                                                    :popover_statistics.sync="popover_week_statistics"
                                                                    :hovered_block.sync="hovered_block"
                                                                    :edited_availability.sync="edited_availability"
                                                                    :week_index="1"
                                                                    :dates="planner_properties.weeks[1].dates"
                                                                    :minute_pixel_width="minute_pixel_width"
                                                                    :read_only="read_only"
                                />
                                
                                <planning-planner-blocks-component 
                                                                    :person.sync="people[index]"                                                                
                                                                    :filters.sync="filters"
                                                                    :popover_statistics.sync="popover_week_statistics"
                                                                    :hovered_block.sync="hovered_block"
                                                                    :edited_availability.sync="edited_availability"
                                                                    :week_index="2"
                                                                    :dates="planner_properties.weeks[2].dates"
                                                                    :minute_pixel_width="minute_pixel_width"
                                                                    :read_only="read_only"
                                />

                                <planning-planner-blocks-component 
                                                                    :person.sync="people[index]"                                                                
                                                                    :filters.sync="filters"
                                                                    :popover_statistics.sync="popover_week_statistics"
                                                                    :hovered_block.sync="hovered_block"
                                                                    :edited_availability.sync="edited_availability"
                                                                    :week_index="3"
                                                                    :dates="planner_properties.weeks[3].dates"
                                                                    :minute_pixel_width="minute_pixel_width"
                                                                    :read_only="read_only"
                                />

                                <div tabindex="0" :id="'popover-person-statistics-' + item.person.id" class="app-planner-blocks-timeline-summary-total">
                                    <div v-bind:class="{
                                                        'app-planner-blocks-timeline-summary-total-red': item.statistics.total != null && item.statistics.total.diff < 0, 
                                                        'app-planner-blocks-timeline-summary-total-green': item.statistics.total != null && item.statistics.total.diff >= 0,
                                                        'app-planner-blocks-timeline-summary-total-selected': item.selected == true }
                                                    "
                                        :style="{'min-height' : item.planning.settings.shifts_height + 13 + 'px'}"> {{item.statistics.total.diff.toString().replace('.', ',')}}
                                    </div> 
                                </div>

                                <b-popover :target="'popover-person-statistics-' + item.person.id" triggers="focus" custom-class="app-local-popover-width">
                                    <template #title><p class="mb-0 app-popover-small-text font-weight-600">4 {{trans('pl-weekly-stats',173)}} </p></template>
                                    

                                    <b-row class="align-items-center">
                                        <b-col start class="mr-0 ml-2 p-0">
                                            <p class="app-popover-small-text  mb-0">Week</p>
                                        </b-col>
                                        <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                                            <p class="app-popover-small-text  mb-0 text-right">{{planner_properties.weeks[0].week_no}}</p>
                                        </b-col>
                                        <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                                            <p class="app-popover-small-text  mb-0 text-right">{{planner_properties.weeks[1].week_no}}</p>
                                        </b-col>
                                        <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                                            <p class="app-popover-small-text  mb-0 text-right">{{planner_properties.weeks[2].week_no}}</p>
                                        </b-col>
                                        <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                                            <p class="app-popover-small-text  mb-0 text-right">{{planner_properties.weeks[3].week_no}}</p>
                                        </b-col>
                                        <b-col cols="auto" end style="max-width:30px; min-width:30px;" class="mr-3 ml-0 p-0">
                                            <p class="app-popover-small-text  mb-0 text-right">T</p>
                                        </b-col>
                                    </b-row>

                                    <hr class="mt-1 mb-2" />
                                    <b-row class="align-items-center">
                                        <b-col start class="mr-0 ml-2 p-0" style="min-width:150px">
                                            <p class="app-popover-small-text mb-0">{{trans('hours-per-week',184)}}</p>
                                            <p class="app-popover-small-text mb-0">{{trans('sh-worked-hours',215)}}</p>
                                            <p class="app-popover-small-text mb-0">{{trans('fp-unavailability',172)}}</p>
                                        </b-col>
                                        <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                                            <p class="app-popover-small-text mb-0 text-right">{{item.statistics.weeks[0].hours.toString().replace('.', ',')}}</p>
                                            <p class="app-popover-small-text mb-0 text-right">{{item.statistics.weeks[0].worked.toString().replace('.', ',')}}</p>
                                            <p class="app-popover-small-text mb-0 text-right">{{item.statistics.weeks[0].notavailable.toString().replace('.', ',')}}</p>
                                        </b-col>
                                        <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                                            <p class="app-popover-small-text mb-0 text-right">{{item.statistics.weeks[1].hours.toString().replace('.', ',')}}</p>
                                            <p class="app-popover-small-text mb-0 text-right">{{item.statistics.weeks[1].worked.toString().replace('.', ',')}}</p>
                                            <p class="app-popover-small-text mb-0 text-right">{{item.statistics.weeks[1].notavailable.toString().replace('.', ',')}}</p>
                                        </b-col>
                                        <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                                            <p class="app-popover-small-text mb-0 text-right">{{item.statistics.weeks[2].hours.toString().replace('.', ',')}}</p>
                                            <p class="app-popover-small-text mb-0 text-right">{{item.statistics.weeks[2].worked.toString().replace('.', ',')}}</p>
                                            <p class="app-popover-small-text mb-0 text-right">{{item.statistics.weeks[2].notavailable.toString().replace('.', ',')}}</p>
                                        </b-col>
                                        <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                                            <p class="app-popover-small-text mb-0 text-right">{{item.statistics.weeks[3].hours.toString().replace('.', ',')}}</p>
                                            <p class="app-popover-small-text mb-0 text-right">{{item.statistics.weeks[3].worked.toString().replace('.', ',')}}</p>
                                            <p class="app-popover-small-text mb-0 text-right">{{item.statistics.weeks[3].notavailable.toString().replace('.', ',')}}</p>
                                        </b-col>
                                        <b-col cols="auto" end style="max-width:30px; min-width:30px;" class="mr-3 ml-0 p-0">
                                            <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{item.statistics.total.hours.toString().replace('.', ',')}}</p>
                                            <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{item.statistics.total.worked.toString().replace('.', ',')}}</p>
                                            <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{item.statistics.total.notavailable.toString().replace('.', ',')}}</p>
                                        </b-col>
                                    </b-row>

                                    <hr class="mt-1 mb-2" />

                                    <b-row class="align-items-center">
                                        <b-col start class="mr-0 ml-2 p-0">
                                            <p class="app-popover-small-text mb-0">{{trans('pl-diff',173)}}</p>
                                        </b-col>
                                        <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                                            <p class="app-popover-small-text mb-0 text-right">{{item.statistics.weeks[0].diff.toString().replace('.', ',')}}</p>
                                        </b-col>
                                        <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                                            <p class="app-popover-small-text mb-0 text-right">{{item.statistics.weeks[1].diff.toString().replace('.', ',')}}</p>
                                        </b-col>
                                        <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                                            <p class="app-popover-small-text mb-0 text-right">{{item.statistics.weeks[2].diff.toString().replace('.', ',')}}</p>
                                        </b-col>
                                        <b-col cols="auto" end style="max-width:28px; min-width:28px;" class="mr-1 ml-0 p-0">
                                            <p class="app-popover-small-text mb-0 text-right">{{item.statistics.weeks[3].diff.toString().replace('.', ',')}}</p>
                                        </b-col>
                                        <b-col cols="auto" end style="max-width:30px; min-width:30px;" class="mr-3 ml-0 p-0">
                                            <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{item.statistics.total.diff.toString().replace('.', ',')}}</p>
                                        </b-col>
                                    </b-row>
                                </b-popover>                

                            </div>
                        </div>      
                        <planning-planner-summary-footer-component :statistics="total_statistics" :planner_properties="planner_properties" v-if="people != null && people.length > 0"/>
                    </div>
                </b-col>
            </b-row>        
        </b-overlay>
            
    </b-card>
</template>


<script>
import axios from "@axios";
import moment from "moment";
import PlanningPlannerTimelineHeaderComponent from './Components/PlanningPlannerTimelineHeaderComponent.vue';
import PlanningPlannerPersonComponent from './Components/PlanningPlannerPersonComponent.vue';
import PlanningPlannerBlocksComponent from './Components/PlanningPlannerBlocksComponent.vue';
import PlanningPlannerSummaryFooterComponent from './Components/PlanningPlannerSummaryFooterComponent.vue';
import UserSettings from '@core/scripts/UserSettings';

export default {
    name: 'app',

    components: {
        PlanningPlannerTimelineHeaderComponent,
        PlanningPlannerBlocksComponent,
        PlanningPlannerPersonComponent,
        PlanningPlannerSummaryFooterComponent
    },

    created(){
        this.user_locations = JSON.parse(localStorage.getItem("USER_LOCATIONS"));
        this.setOffice();
        this.onCreateThis();    
    },

    computed: {       
        
    },
  
    props:[],
    
    data() {
        return {
            hovered_block: {
                type: null,
                id: null
            },

            edited_availability: {
                week_index: null,
                item: null,
                id_person_data_main: null
            },

            popover_week_statistics: {
                show: false,
                target: 'popover-week-statistics',
                values: {
                    hours: 0,
                    worked: 0,
                    notavailable: 0,
                    diff: 0
                }
            },
            minute_pixel_width: null,
            loading: false,
            loading_list: false,
            refreshUserLocations:0,
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            filters : {
                type: 1,
                search: '',
                week: moment().format('YYYY-W'),
                weeks_count: 4,
                period: moment().format('YYYY-W'),
                period_first_week: null,
                office: null,
                show_registered: false
            },
            people:[],
            total_statistics: {
                all: {},
                weeks: [
                    {},
                    {},
                    {},
                    {}
                ]
            },
            
            planner_properties: {
                dates: [],
                weeks: []                
            },
            refresh: 0,
            read_only: false,
            userSettings: new UserSettings(),
        };
    },
    
    methods: {            

            getList() {
                
                 if (this.filters.office != null) {

                    if(this.filters.type == 1 && (this.filters.period_first_week == null || this.filters.period_first_week == '')){
                        this.setPeriodFirstWeek();
                        this.refresh++;
                    }else if(this.filters.type == 2 && (this.filters.week == null || this.filters.week == '')){
                        this.filters.week = moment().format('YYYY-W');
                        this.refresh++;
                    }

                    this.loading_list = true;
                    axios
                        .post("planning/planner/getPlan", {
                            filters: JSON.stringify(this.filters)
                        })
                        .then((res) => {
                            let selected_person = this.people.filter(item => item.selected == true);
                            this.people = res.data.people;
                            this.total_statistics = res.data.total_statistics;
                            this.calculateDatesAndWeeks(res.data.start_date);

                            if (selected_person.length > 0) {
                                 this.people.forEach(person => {
                                    if (person.person.id == selected_person[0].person.id) {
                                        person.selected = true;
                                    }
                                });
                            }

                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                             this.loading_list = false;
                        })
                    }
            },

            setPeriodFirstWeek(){
                if(this.filters.period != null){
                    let periodFromWeek = 0;

                    let year = this.filters.period.substring(0, this.filters.period.indexOf('-'));
                    let week = this.filters.period.substring(this.filters.period.indexOf('-') + 1);

                    periodFromWeek = Math.floor((week-1) / 4) + 1;

                    let date = moment().day("Monday").year(year).isoWeek((periodFromWeek * 4) - 3).toDate();
                    let isoWeek = moment(date).isoWeek();

                    this.filters.period_first_week = year + '-' + isoWeek;
                }
            },
              
            selectPerson(item) {
                if (this.edited_availability.item == null) {
                    let status = !item.selected;
                    this.people.forEach(person => {
                        person.selected = false;
                    });

                    item.selected = status;
                }
                
            },

            setOffice() {
                if(this.filters.office == null){                
                    if(this.user_locations.length == 1){
                        this.filters.office = this.user_locations[0];                    }
                }               
                
            },
            
            calculateDatesAndWeeks(startDate){
                let start_date = moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
                let start_week = moment(startDate, 'YYYY-MM-DD');

                this.planner_properties.weeks = [];

                for(let w=0; w<4; w++) {
                    
                    let dates = [];
                    
                    for(let d=0; d<7; d++) {
                        start_date = moment(start_date, 'DD-MM-YYYY').isoWeekday(d+1);
                        let label = start_date.locale('nl').format('dd');
                        label = label.charAt(0).toUpperCase() + label.slice(1);
                        
                        dates.push({
                                        index: d,
                                        day_name: label, 
                                        short_date: start_date.format('DD-MM'), 
                                        date: start_date.format('DD-MM-YYYY'),
                                        is_today: start_date.format('DD-MM-YYYY') == moment().format('DD-MM-YYYY'),
                                        is_weekend: start_date.isoWeekday() < 6 ? false : true
                                    });
                        
                        
                    }
                    
                    start_date = start_date.add(1, 'weeks').format('DD-MM-YYYY');
                    
                    this.planner_properties.weeks.push( {
                                                            index: w,
                                                            week_no: start_week.format('W'),
                                                            week: start_week.format('YYYY-W'), 
                                                            label: 'Week ' + start_week.format('W'),
                                                            dates: dates,
                                                            is_selected: this.filters.week == start_week.format('YYYY-W') ? true : false
                                                        });
                    
                    start_week = start_week.add(1, 'weeks');                   

                }
        },

        saveSettings() {
            this.userSettings.saveSettings(this.filters, 17);
        },

        async loadSettings() {
            var getData = JSON.parse(localStorage.getItem('userSettings'));
            
            if(getData == undefined || getData.plannerList == undefined){
                await this.userSettings.saveSettings(this.filters, 17);
                getData = JSON.parse(await localStorage.getItem('userSettings'));
            }
            var sFilters = getData.plannerList

            this.filters.office = sFilters.office;
            this.filters.week = sFilters.week; 
            this.filters.weeks_count = sFilters.weeks_count;       
            this.filters.type = sFilters.type;   
            this.filters.period = sFilters.period;
            
            if(sFilters.period_first_week == null){
                this.setPeriodFirstWeek();
            }else{
                this.filters.period_first_week = sFilters.period_first_week;
            }
        },

        onCreateThis() { 
            
            this.loadSettings();
            this.getList(); 

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.type', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });
           
            this.$watch('refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.edited_availability.id_person_data_main = null;
                    this.edited_availability.item = null;
                    this.edited_availability.week_index = null;
                    
                    this.getList();
                }   
            });

            this.$watch('filters.office', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                }   
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.week', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                }   
            });

            this.$watch('filters.period_first_week', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                }   
            });

            this.$watch('filters.show_registered', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

        },

        
    },

    
};
</script>

<style scoped>
.custom-avatar{
    height: 52px;
    width: 52px
}

.app-local-bold-text{
    font-weight:700;
    color:#174066;
    font-size: 14px !important;
}

.app-local-text{
    color:#174066;
    font-size: 12px !important;
}

.app-local-title-height{
    min-height: 30px;
}

</style>
<style>
.app-local-popover-width{
    min-width: 340px !important;
}
</style>
